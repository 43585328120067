<template>
    <div class="space-y-2" style="width: 34vw">
        <div class="flex justify-between">
            <h1 class="font-bold">{{ year }} {{ accountTitle.name }}</h1>
            <el-checkbox aria-label="Visa konton utan saldo" label="Visa konton utan saldo" v-model="showAllAccounts" />
        </div>
        <div v-for="account in filteredAccounts" :key="account.account">
            <div v-if="showAllAccounts || (!showAllAccounts && nonZeroAccount(account))" class="flex flex-row justify-between">
                <div class="flex flex-row space-x-4 items-center pr-4">
                    <p>{{ account.account }}</p>
                    <p>{{ account.name }}</p>
                </div>
                <div class="flex flex-row space-x-2 items-center">
                    <p>{{ isPeriod ? account.periodRounded : account.previousPeriodRounded | swedishNumberFormat(true) }} SEK</p>
                    <el-button @click="handleMoveAccountsModal(account)" type="default" plain class="p-1">
                        <i v-if="(isPeriod && !account.periodIsMoved) || (!isPeriod && !account.previousPeriodIsMoved)" class="fa fa-right-left" />
                        <i v-else-if="accountIsMoved" class="fa-solid fa-right-left" style="color: #74C0FC;" />
                    </el-button>
                </div>
            </div>
        </div>
        <h1 class="font-bold">Summa: {{ isPeriod ? accountTitle.periodRounded : accountTitle.previousPeriodRounded | swedishNumberFormat(true) }}</h1>
    </div>
</template>

<script>
import moment from "moment";

export default {
    props: {
        annualReportId: {
            type: String,
        },
        visible: {
            type: Boolean,
        },
        clientId: {
            type: String,
        },
        clientInfo: {
            type: Object,
        },
        selectedAccountsData: {
            type: Array,
        },
        titlesData: {
            type: Array,
        },
        isPeriod: {
            type: Boolean,
        },
        balanceSheetData: {
            type: Object,
        },
    },
    data() {
        return {
            formData: {},
            accountsPlanTypes: [],
            showAllAccounts: false,
        };
    },
    methods: {
        resetModalState() {
            this.formData = {};
        },
        handleMoveAccountsModal(account) {
            this.$emit("show-move-accounts-modal", account);
        },
        formatDate(date) {
            if (!date) return;
            return moment(date).format("YYYY");
        },
    },
    computed: {
        filteredAccounts() {
            return this.selectedAccountsData.filter(account => account.type === 4);
        },
        accountTitle() {
            return this.selectedAccountsData.find(account => account.type === 5);
        },
        nonZeroAccount() {
            return account => !((this.isPeriod && account.period === 0) || (!this.isPeriod && account.previousPeriod === 0));
        },
        accountIsMoved() {
            return account => (this.isPeriod && account.periodIsMoved) || (!this.isPeriod && account.previousPeriodIsMoved);
        },
        year() {
            return this.isPeriod ? this.formatDate(this.balanceSheetData.period.startDate) : this.formatDate(this.balanceSheetData.previousPeriod.startDate);
        },
    },
};
</script>
